<template>
  <div>
    <hero-global subtitle="Información sobre pre-registros informáticos de uso de esta página" title="Información " />
    <div class="container">
      <section class="p-x-3-rem">
        <h2 class="subtitle font-size-24">1. ¿Qué son los pre-registros?</h2>
        <br />
        <article class="font-family-roboto font-light">
          <p>La Web de <strong>Comit&eacute; Espa&ntilde;ol de Representantes de Personas con Discapacidad</strong> (en
            adelante la Web) utiliza  pre-registros. Los pre-registros son ficheros enviados a un navegador por medio de un servidor
            web para registrar las actividades del Usuario en una web determinada. La primera finalidad de los pre-registros
            es la de facilitar al usuario un acceso m&aacute;s r&aacute;pido a los servicios seleccionados. Adem&aacute;s,
            los pre-registros personalizan los servicios que ofrece la Web, facilitando y ofreciendo a cada usuario informaci&oacute;n
            que es de su inter&eacute;s o que puede ser de su inter&eacute;s, en atenci&oacute;n al uso que realiza de
            los Servicios.<br /><br />La Web utiliza  pre-registros para personalizar y facilitar al m&aacute;ximo la navegaci&oacute;n
            del usuario. Los pre-registros se asocian &uacute;nicamente a un usuario an&oacute;nimo y su ordenador y no
            proporcionan referencias que permitan deducir datos personales del usuario. El usuario podr&aacute;
            configurar su navegador para que notifique y rechace la instalaci&oacute;n los pre-registros enviadas por la Web,
            sin que ello perjudique la posibilidad del usuario de acceder a los contenidos de dicha web. Sin embargo, le
            hacemos notar que, en todo caso, la calidad de funcionamiento de la p&aacute;gina Web puede
            disminuir.<br /><br />Los usuarios registrados, que se registren o que hayan iniciado sesi&oacute;n, podr&aacute;n
            beneficiarse de unos servicios m&aacute;s personalizados y orientados a su perfil, gracias a la combinaci&oacute;n
            de los datos almacenados en los pre-registros con los datos personales utilizados en el momento de su registro.
            Dichos usuarios autorizan expresamente el uso de esta informaci&oacute;n con la finalidad indicada, sin
            perjuicio de su derecho a rechazar o deshabilitar el uso de  pre-registros. <br /><br />Asimismo, la Web podr&aacute;
            saber todos los servicios solicitados por los usuarios, de forma que podr&aacute;n facilitar u ofrecer
            informaci&oacute;n adecuada a los gustos y preferencias de cada usuario.</p>
        </article>
      </section>

      <section class="p-3-rem">
        <h2 class="subtitle font-size-24">2. ¿Qué tipos de pre-registros existen?</h2>
        <br />
        <article class="font-family-roboto font-light">
          <p><strong>Los pre-registros, en función de su Permanencia, pueden dividirse en:</strong>
          </p>
          <p><strong class="text-primary"> pre-registros de sesi&oacute;n</strong><br />Expiran cuando el usuario cierra el
            navegador.<br /><br /><strong class="text-primary"> Pre-registros persistentes</strong><br />Expiran en funci&oacute;n
            de cuando se cumpla el objetivo para el que sirven (por ejemplo, para que el usuario se mantenga
            identificado en los Servicios) o bien cuando se borran manualmente.<br /><br />En funci&oacute;n de su
            <strong>Propietario</strong>, pueden dividirse en:<br /><br /><strong class="text-primary"> Pre-registros
              propias</strong><br />Son aqu&eacute;llas que se env&iacute;an al equipo terminal del usuario desde un
            equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el
            usuario.<br /><br /><strong class="text-primary"> Pre-registros de terceros</strong><br />Son aqu&eacute;llas que se
            env&iacute;an al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor,
            sino por otra entidad que trata los datos obtenidos trav&eacute;s de los pre-registros.<br /><br />Adicionalmente,
            en funci&oacute;n de su <strong>Objetivo</strong>, los pre-registros pueden clasificarse de la siguiente
            forma:<br /><br /><strong class="text-primary"> Pre-registros de rendimiento</strong><br />Este tipo de pre-registro informático
            recuerda sus preferencias para las herramientas que se encuentran en los servicios, por lo que no tiene que
            volver a configurar el servicio cada vez que usted visita. A modo de ejemplo, en esta tipolog&iacute;a se
            incluyen:<br />Ajustes de volumen de reproductores de v&iacute;deo o sonido.<br />Las velocidades de transmisi&oacute;n
            de v&iacute;deo que sean compatibles con su navegador.<br /><br /><strong class="text-primary"> Pre-registros de
              Geo-localizaci&oacute;n</strong><br />Estas  pre-registros son utilizadas para averiguar en qu&eacute; pa&iacute;s
            se encuentra cuando se solicita un servicio. Este pre-registro informático es totalmente an&oacute;nimo, y s&oacute;lo se
            utiliza para ayudar a orientar el contenido a su ubicaci&oacute;n.<br /><br /><strong class="text-primary"> Pre-registros
              de registro</strong><br />Los pre-registros de registro se generan una vez que el usuario se ha registrado o
            posteriormente ha abierto su sesi&oacute;n, y se utilizan para identificarle en los servicios con los
            siguientes objetivos:<br />
          </p>
          <ul class="p-x-1-rem">
            <li>Mantener al usuario identificado de forma que, si cierra un servicio, el navegador o el ordenador y en
              otro momento u otro d&iacute;a vuelve a entrar en dicho servicio, seguir&aacute; identificado, facilitando
              as&iacute; su navegaci&oacute;n sin tener que volver a identificarse. Esta funcionalidad se puede suprimir
              si el usuario pulsa la funcionalidad &ldquo;cerrar sesi&oacute;n&rdquo;, de forma que este pre-registro informático se
              elimina y la pr&oacute;xima vez que entre en el servicio el usuario tendr&aacute; que iniciar sesi&oacute;n
              para estar identificado.<br /></li>
            <li>Comprobar si el usuario est&aacute; autorizado para acceder a ciertos servicios, por ejemplo, para
              participar en un concurso.<br/><br/></li>
          </ul><br/>
          <strong class="text-primary"> pre-registros anal&iacute;ticas</strong>
          <p>Cada vez que un Usuario visita un servicio, una herramienta de un proveedor externo genera un pre-registro informático anal&iacute;tico
            en el ordenador del usuario. Este pre-registro informático que s&oacute;lo se genera en la visita, servir&aacute; en pr&oacute;ximas
            visitas a los Servicios de la Web para identificar de forma an&oacute;nima al visitante. Los objetivos
            principales que se persiguen son:<br /></p>
          <ul class="p-x-1-rem">
            <li>Permitir la identificaci&oacute;n an&oacute;nima de los usuarios navegantes a trav&eacute;s de la
              &ldquo;pre-registro informático&rdquo; (identifica navegadores y dispositivos, no personas) y por lo tanto la contabilizaci&oacute;n
              aproximada del n&uacute;mero de visitantes y su tendencia en el tiempo.<br /></li>
            <li>Identificar de forma an&oacute;nima los contenidos m&aacute;s visitados y por lo tanto m&aacute;s
              atractivos para los usuarios.<br /></li>
            <li>Saber si el usuario que est&aacute; accediendo es nuevo o repite visita.<br /></li>
            <li>Importante: Salvo que el usuario decida registrarse en un servicio de la Web, el &ldquo;pre-registro informático&rdquo;
              nunca ir&aacute; asociado a ning&uacute;n dato de car&aacute;cter personal que pueda identificarle. Dichas
               pre-registros s&oacute;lo ser&aacute;n utilizadas con prop&oacute;sitos estad&iacute;sticos que ayuden a la
              optimizaci&oacute;n de la experiencia de los Usuarios en el sitio.
            </li>
          </ul>
        </article>
      </section>

      <!-- <section class="p-x-3-rem">
        <h2 class="subtitle font-size-24">3. ¿Qué  pre-registros utilizamos?</h2>
        <br />
        <article class="font-family-roboto font-light">
          <p>Los pre-registros que utilizamos en nuestra p&aacute;gina web son:<br /><br /><span class="text-primary text-bold">PROPIAS:</span><br /><strong> pre-registros
              t&eacute;cnicas: </strong><br />Caducidad: sesi&oacute;n, un d&iacute;a, un a&ntilde;o....<br />Finalidad:
            xxxxxx<br /><br /><strong> pre-registros anal&iacute;ticas: </strong><br />Caducidad: sesi&oacute;n, un d&iacute;a, un
            a&ntilde;o....<br />Finalidad: xxxxxx<br /><br /><strong> pre-registros publicitarias:</strong><br />Caducidad: sesi&oacute;n,
            un d&iacute;a, un a&ntilde;o....<br />Finalidad: xxxxxx<br /><br /><span class="text-primary text-bold">DE TERCEROS:</span><br /><strong> pre-registros
              t&eacute;cnicas: </strong><br />Caducidad: sesi&oacute;n, un d&iacute;a, un a&ntilde;o....<br />Finalidad:
            xxxxxx<br /><br /><strong> pre-registros anal&iacute;ticas: </strong><br />Caducidad: sesi&oacute;n, un d&iacute;a, un
            a&ntilde;o....<br />Finalidad: xxxxxx<br /><br /><strong> pre-registros publicitarias:</strong><br />Caducidad: sesi&oacute;n,
            un d&iacute;a, un a&ntilde;o....<br />Finalidad: xxxxxx</p>
        </article>
      </section> -->

      <section class="p-3-rem">
        <h2 class="subtitle font-size-24">3. ¿Existen trasferencias internacionales?</h2>
        <br />
        <article class="font-family-roboto font-light">
          <p>Puedes informarte de las transferencias a terceros países que, en su caso, realizan los terceros
            identificados en esta política de  pre-registros en sus correspondientes políticas (ver los enlaces facilitados en
            el apartado “ pre-registros de terceros”). Los diferentes mecanismos de regularización de la transferencia
            internacional, en el caso de existir, serán informados por dichos terceros.</p>
        </article>
      </section>

      <section class="p-x-3-rem">
        <h2 class="subtitle font-size-24">4. ¿Cómo deshabilitar los pre-registros?</h2>
        <br />
        <article class="font-family-roboto font-light">
          <p>Normalmente es posible dejar de aceptar los pre-registros del navegador, o dejar de aceptar los pre-registros de un
            Servicio en particular.<br /><br />Todos los navegadores modernos permiten cambiar la configuraci&oacute;n de
             pre-registros. Estos ajustes normalmente se encuentran en las &uml;opciones&uml; o &uml;preferencias&uml; del men&uacute;
            de su navegador. Asimismo, puede configurar su navegador o su gestor de correo electr&oacute;nico, as&iacute;
            como instalar complementos gratuitos para evitar que se descarguen los Web Bugs al abrir un email.<br /><br />La
            Web ofrece orientaci&oacute;n al Usuario sobre los pasos para acceder al men&uacute; de configuraci&oacute;n
            de los pre-registros y, en su caso, de la navegaci&oacute;n privada en cada uno de los navegadores
            principales:<br /><br /><span class="text-bold text-primary">Internet Explorer</span><br />Herramientas
            Opciones de Internet Privacidad Configuraci&oacute;n.<br />Para m&aacute;s informaci&oacute;n, puede
            consultar el soporte de Microsoft o la Ayuda del navegador.<br /><br /><span class="text-primary text-bold">Microsoft Edge </span><br />Configuraci&oacute;n
            y m&aacute;s Configuraci&oacute;n Borrar datos de exploraci&oacute;n.<br /><br /><span class="text-bold text-primary">Firefox</span><br />Herramientas Opciones Privacidad Historial Configuraci&oacute;n
            personalizada.<br /><br /><span class="text-primary text-bold">Chrome</span><br />Configuraci&oacute;n
            Configuraci&oacute;n avanzada Privacidad y seguridad Borrar datos de navegaci&oacute;n.<br /><br /><span class="text-bold text-primary">Safari</span><br />Preferencias Seguridad.<br />Para m&aacute;s informaci&oacute;n,
            puede consultar el soporte de Apple o la Ayuda del navegador.</p>
        </article>
      </section>

      <section class="p-3-rem">
        <h2 class="subtitle font-size-24">5. ¿Se pueden producir modificaciones de la Política de  pre-registros?</h2>
        <br />
        <article class="font-family-roboto font-light">
          <p>La Web puede modificar esta Pol&iacute;tica de  pre-registros en funci&oacute;n de las exigencias legislativas,
            reglamentarias, o con la finalidad de adaptar dicha pol&iacute;tica a las instrucciones dictadas por la
            Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos, por ello se aconseja a los usuarios que la visiten
            peri&oacute;dicamente. <br /><br />Cuando se produzcan cambios significativos en esta Pol&iacute;tica de
             pre-registros, se comunicar&aacute;n a los usuarios bien mediante la web o a trav&eacute;s de correo electr&oacute;nico
            a los usuarios registrados.&nbsp;</p>
        </article>
      </section>
    </div>
    <br />
    <br />
    <br />
  </div>
</template>

<script>
  import HeroGlobal from "@/components/Global/HeroGlobal";

  export default {
    name: " pre-registrosPolicyView",
    components: { HeroGlobal }
  }
</script>