<template>
  <div class="hero-content">
    <div class="container">
      <div class="d-flex h-100 justify-content-between align-items-start p-top-50-px">
        <div :class="`w-${img !== undefined ? setWidth.firstWidth : '100'} h-70 p-x-6-rem position-relative`">
          <h1 class="font-family-frank-ruhl text-primary-light text-bold font-size-6-rem w-100">
            {{ title }}
          </h1>
          <p v-if="subtitle" class="subtitle">{{ subtitle }}</p>
          <template v-else>
            <p class="subtitle">{{ title }}</p>
          </template>
          <br />
          <p>
            <router-link class="font-family-roboto text-primary font-size-16 text-decoration-none" :to="{ name: 'home' }" title="Ir a Inicio cermi.es" alt="Ir a Inicio cermi.es">
              INICIO -
            </router-link>
            <router-link class="font-family-roboto text-primary font-size-16 text-decoration-none" :to="router.fullPath" title="Página Actual" alt="Página Actual"
              aria-current="location">
              {{ breadcrumb ?? title }}
            </router-link>
          </p>
        </div>
        <div :class="` w-${setWidth.lastWidth}`" v-if="img !== undefined">
          <img class="d-block margin-auto hero__img" :src="img" alt="cabecera" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
export default {
  name: "HeroGlobal",
  props: ["img", "title", "subtitle", "breadcrumb", "firstWidth", "lastWidth"],
  computed: {
    setWidth: ({ firstWidth = "60", lastWidth = "30" }) => ({ firstWidth, lastWidth })
  },
  setup() {
    const router = useRoute();

    return {
      router
    }
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/mixins";

.hero-content {
  background-image: url(/img/hero/hero.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 -8vh;
  margin-bottom: 20px;

  h1 {
    line-height: 0.8em;
    opacity: 0.5 !important;
  }

  .subtitle {
    bottom: 25%;
    left: 12%;
    color: black;
    font-size: 30px;
    position: absolute;
  }

  @include sm {
    height: auto !important;
  }

  @include lg {
    height: 300px;
  }

  @include xl {
    height: 320px;
  }

  @include xxl {
    height: 480px;
  }

  .hero__img {
    @include sm {
      height: 100%;
    }

    @include lg {
      width: 70%;
    }

    @include xl {
      width: 70%;
    }

    @include xxl {
      width: 90%;
    }
  }
}
</style>
